* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat' sans-serif;
  /* background-color: rgb(22,34,46); */
}

.home {
  background-color: rgb(22,34,46);
}

/* .components {
  background-color: rgb(22,34,46);
  margin: 0;
  padding: 0;
} */