.Slider {
   display: flex;
   align-items: center;
   margin: 20px;
   opacity: 0;
   transform: scale(85%);
   transition: 2s;
}

.slider-zoom {
   opacity: 1;
   transform: scale(100%);
   transition: 1s;
}

.slider-tile {
   display: flex;
   flex-direction: row;
   padding: 20px;
   color: white;
}

.slider-img {
   width: 60%;
}

.slider-content {
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 20px;
   color: white;
   text-align: center;
   border-style: solid;
   border-width: 0px 0px 1px 0px;
   border-color: transparent;
   transition: 250ms;
   text-decoration: none;
}

.slider-content:hover {
   transition: 250ms;
   text-decoration: none;
   box-shadow: 1px 3px 5px black;
}

.slider-title {
   text-align: center;
   text-decoration: none;
   border-style: solid;
   border-width: 0px 0px 1px 0px;
   border-color: transparent;
   transition: 250ms;
}

.slider-content:link { text-decoration: none; color: white;}
.slider-content:visited { text-decoration: none; color: white;}
.slider-content:hover { text-decoration: none; color: white;}
.slider-content:active { text-decoration: none; color: white;}

.slider-title:link { text-decoration: none; color: white;}
.slider-title:visited { text-decoration: none; color: white;}
.slider-title:hover { 
   border-color: white;
   transition: 250ms;
   text-decoration: none;}
.slider-title:active { text-decoration: none; color: white;}


@media screen and (max-width:800px) {
   .slider-tile {
      flex-direction: column;
      background-color: rgb(236, 236, 236);
   }

   .slider-title {
      color: black;
   }

   .slider-subtitle {
      color: black;
   }

   .slider-img {
      width: 100%;
   }
}
