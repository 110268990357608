.About {
   max-width: 900px;
   text-align: left;
   margin: 40px auto;
   padding: 20px;
   color: black;
}

.header {
   height: 300px;
   text-align: center;
   background-color: rgb(33, 47, 61);
   margin-bottom: 40px;
   
}

.header-text {
   display: inline-block;
   padding: 100px 0;
   padding-left: 10px;
   line-height: normal;
   color: white;
}

a:link, a:visited, a:hover, a:active {
   color: white;
   padding: 14px 5px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
 }

.about-me-panel {
   display: flex;
   flex-direction: row;
   margin-bottom: 40px;
   padding: 10px;
   border: 1px solid rgb(235, 235, 235); 
   -webkit-box-shadow: 0 0 5px 2px rgb(77, 77, 77);
    -moz-box-shadow: 0 0 5px 2px rgb(65, 64, 64);
    box-shadow: 0 0 15px 5px rgb(226, 226, 226);
}

.about-me-summary {
   max-width: 500px;
   margin-right: 100px;
   line-height: 1.5;
}

.about-me-title {
   padding-bottom: 10px;
   padding-top: 5px;
}

.about-me-data {
   line-height: 2;
}

.about-me-basic {
   height: 39px;
   padding-bottom: 10px;
   padding-top: 5px;
   justify-content: left;
}

.about-me-list {
   padding-bottom: 5px;
}

.professional-skills {
   display: flex;
   flex-direction: row;
   padding-top: 20px;
   margin-bottom: 40px;
   padding: 10px;
   border: 1px solid rgb(235, 235, 235); 
   -webkit-box-shadow: 0 0 5px 2px rgb(77, 77, 77);
    -moz-box-shadow: 0 0 5px 2px rgb(65, 64, 64);
    box-shadow: 0 0 15px 5px rgb(226, 226, 226);
}

.professional-skills-title {
   
}

.skills {
   padding: 20px;
   line-height: 2;
   justify-content: center;
}

.skills-more {
   padding: 20px;
   line-height: 2;
   justify-content: center;
}

.projects {
   padding-top: 20px;
   margin-bottom: 40px;
   padding: 10px;
   border: 1px solid rgb(235, 235, 235); 
   -webkit-box-shadow: 0 0 5px 2px rgb(77, 77, 77);
    -moz-box-shadow: 0 0 5px 2px rgb(65, 64, 64);
    box-shadow: 0 0 15px 5px rgb(226, 226, 226);
}

.project-content {
   padding: 20px;
}

.work-expirences {
   padding-top: 20px;
   margin-bottom: 40px;
   padding: 10px;
   border: 1px solid rgb(235, 235, 235); 
   -webkit-box-shadow: 0 0 5px 2px rgb(77, 77, 77);
    -moz-box-shadow: 0 0 5px 2px rgb(65, 64, 64);
    box-shadow: 0 0 15px 5px rgb(226, 226, 226);
}