.navbar {
   position: sticky;
   top: 0px;
   height: 70px;
   background-color: black;
   opacity: .75;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0px 30px 0px 30px;
   margin-top: 0px;
   z-index: 5;
}

.navbar-logo {
   color: white;
   font-family: 'Cinzel', serif;
   font-size: 2.5rem;
}

.navbar-menu {
   color: white;
   cursor: pointer;
   display: none;
}

@media screen and (max-width: 800px){
   .navbar-list {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      top: 70px;
      left: -100%;
      width: 100%;
      height: 300px;
      transform: all 500ms;
   }

   .navbar-list--active {
      left: 0;
   }

   .navbar-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      flex: 1;
   }

   .navbar-link {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 0px;
      border-width: 0px;
      background-color: black;
      opacity: .50;
   }

   .navbar-link:hover {
      opacity: .9;
      border-color: transparent;
   }

   .navbar-menu {
      display: block;
   }
   
}

.navbar-list {
   display: flex;
   list-style-type: none;
}

.navbar-item {
   white-space: nowrap;
}

.navbar-link {
   font-size: .85rem;
   text-decoration: none;
   color: white;
   text-align: center;
   padding: 10px;
   margin: 0px 5px;
   border-style: solid;
   border-width: 0px 0px 1px 0px;
   border-color: transparent;
   transition: 250ms;
}

.navbar-link:hover {
   border-color: white;
   transition: 250ms;
   text-decoration: none;
}


a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

